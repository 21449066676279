























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Forbidden',

  props: {
    resource: {
      type: String,
      default: '',
    },
  },
})
